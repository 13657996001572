























import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
import { AppProvider } from '@/app-providers'

@Observer
@Component
export default class CardHover extends Vue {
  @Prop({ default: false }) disableHover
  @Prop({ default: false }) disableShadow
  @Prop({ default: false }) darker
  @Prop({ default: null }) maxWidth
  @Prop({ default: 'xl' }) rounded
  @Prop({ default: false }) blendOverlay
  @Prop({ default: true }) hasMaterialCardBg
  @Prop({ default: 'blur-md' }) blurLevel
}
